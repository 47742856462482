import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuSpecSpecialEdit from './MenuSpecSpecialEdit';
import { Button, Space, Switch } from 'antd';
import Synchronize from './Synchronize';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import MenuSpecSpecialUploadFile from './MenuSpecSpecialUploadFile';

export default function MenuSpecSpecial(props: any) {

    const ref = useRef<any>();

    const { closeModal, data } = props;

    //规格窗口
    const [relatePrinterVisible, setRelatePrinterVisible] = useState(false);

    //规格数据
    const [relatePrinterData, setRelatePrinterData] = useState<any>();

    /** api服务id */
    const apiId = 'menuSpecSpecial';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const initData = async () => {
        // console.log(data.menuSpecId);
        const menuSpecId = data.menuSpecId;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                menuSpecId: menuSpecId,
                foodType: data.foodType
            }
        }
        ref.current.initData(currentData);
    }

    //关闭关联打印机窗口
    const onCloseRelatePrinter = async (status: boolean) => {
        setRelatePrinterVisible(false)
        if (status) {
            await initData();
        }
    }

    //清除关联打印机
    const handleCancelRelatePrinter = (record: any) => {
        // console.log(record);
        fetchApi.PUT(wmService, {
            apiId: "menuSpecSpecial",
            apiExtend: 'cancelSetMealRelation',
            allData: record
        }).then(async res => {
            if (res.success) {
                antMessage('success', '清空成功', 'success');
                await initData();
            }
        })
    };

    //打开关联打印机
    const handleRelatePrinter = (record: any) => {
        // console.log(record);
        sendData.menuSpecSpecialId = record.id;
        sendData.menuSpecSpecialName = record.menuSpecSpecialName;
        sendData.setMealRelationId = record.setMealRelationId;
        sendData.foodType = data.foodType;
        setRelatePrinterData(sendData);
        setRelatePrinterVisible(true);
    };

    const handleDelete = (record: any) => {
        fetchApi.DELETE(wmService, {
            apiId,
            apiVariable: record.id,
            apiExtend: "remove"
        }).then(async res => {
            if (res.success) {
                antMessage('success', '删除成功', 'success');
                await initData();
                if (res.data.state) {
                    closeModal(true);
                }
            }
        })
    };

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menuSpecSpecial",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //表头
    const columnsHead = [
        {
            title: data.foodType === 'setMeal' ? '套餐类别' : '规格',
            dataIndex: 'menuSpec',
            render: (text: any, record: any) =>
                <div>{record.menuSpec.menuSpecName}</div>
        },
        {
            title: data.foodType === 'setMeal' ? '套餐菜式' : '规格特殊属性',
            dataIndex: 'menuSpecSpecialName',
        },
        {
            title: data.foodType === 'setMeal' ? '价格' : '加价',
            dataIndex: 'price',
        },

        data.foodType === 'setMeal' ?
            {
                title: '数量',
                dataIndex: 'amount',
            } : {},

        data.foodType === 'setMeal' ?
            {
                title: '单位',
                dataIndex: 'productUnitId',
                render: (text: any, record: any) => {
                    if (record.productUnitId) {
                        return (
                            <div>{record.productUnit.productUnitName}</div>
                        )
                    }
                }
            } : {},

        data.foodType === 'setMeal' ?
            {
                title: '强制数量',
                dataIndex: 'isMandatory',
                render: (text: any, record: any) => (
                    <Switch checkedChildren="强制" unCheckedChildren="不强制"
                        checked={text} key={record.id}
                    />
                )
            } : {},

        {
            title: '关联相关菜式 (匹配打印机)',
            dataIndex: 'relatePrinter',
            key: 'relatePrinter',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleRelatePrinter(record)}>{'关联相关菜式 (匹配打印机)'}</Button>
                    </Space>
                )
            }
        },
        {
            title: '当前关联相关菜式 (匹配打印机)',
            dataIndex: 'nowPrinter',
            key: 'nowPrinter',
            render: (text: any, record: any) => {
                if (record.setMealRelation) {
                    return (
                        <div>{"【" + record.setMealRelation.menuSpec.menu.menuHead.menuHeadName + "】" + record.setMealRelation.menuSpec.menu.foodName + " ( " + record.setMealRelation.menuSpec.menuSpecName + " )"}&nbsp;&nbsp;&nbsp;&nbsp;<Button size={"small"} type="primary" onClick={() => handleCancelRelatePrinter(record)}>{'清除关联'}</Button></div>
                    )
                }
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '客服专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isStaff', e)}
                    />
                )
            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuSpecSpecialId: '',
        menuSpecSpecialName: '',
        setMealRelationId: '',
        path: 'MenuSpecSpecial',
        key: 'RelatePrinter',
        foodType: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: data.foodType === 'setMeal' ? '套餐菜式' : '菜谱规格特殊属性',
        apiId,
        columns,
        showImage: true,
        showUploadFile: true,
        showStates: true,
        showDefault: true,
        pagination: true,
        // showDelete: false,
        showAdd: false,
        extendIdName: "menuSpecId",
        EditComponent: MenuSpecSpecialEdit,
        arrayFilesName: 'menuSpecSpecialFiles',
        UploadFileComponent: MenuSpecSpecialUploadFile,
        sendPageData: {
            menuSpecId: data.menuSpecId,
            foodType: data.foodType
        },
        coverHandleDelete: handleDelete
    };

    return (
        <>
            <AntDraggableModal
                title={data.foodType === 'setMeal' ? '套餐菜式' : '菜谱规格特殊属性'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1600}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {
                relatePrinterVisible
                    ? (<Synchronize
                        closeModal={onCloseRelatePrinter} data={relatePrinterData} />)
                    : null
            }
        </>
    )
}