import { Button, Checkbox, Drawer, Dropdown, Input, List, MenuProps, Popconfirm, Select, Space, Tooltip } from 'antd'
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
// import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification';
import { DownOutlined } from '@ant-design/icons';
import getList from '../../universal/getList';
export default function DrawerDetailList(props: any) {
    const { data, closeModal } = props;
     
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    // const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [menuData, setMenuData] = useState<any>();
    const [selectAll, setSelectAll] = useState(false);
    const [selectedData, setSelectedData] = useState<Array<any>>([]);
    /**打印机 */
    const [printData, setPrintData] = useState<number>(0)

    // const [returnData,setReturnData]=useState<>
    const [allData, setAllData] = useState<Array<any>>([]);

    const [printerIds, setPrinterIds] = useState<Array<any>>([]);
    useEffect(() => {
        initData()
    }, [])
    //初始化数据
    const initData = (open?: number) => {
        fetchApi.GET(wmService, {
            apiId: "cateringOrder",
            apiExtend: "checkMenu",
            apiData: {
                caMenuStatus: data.orderPaymentModeId === "payFirst" ? [1, 2, 3, 999] : data.caMenuStatus,
                id: data.id,
                dataStatusType: data.dataStatusType,
                // time: data.time,
                // noDeclinedAndPlace: data.noDeclinedAndPlace
            }
        }).then((res) => {
            if (res.success) {
                let data = res.data;
                let newAllData: Array<string> = [];
                for (let i = 0; i < data.length; i++) {
                    newAllData.push(data[i].id)
                }
                setAllData(newAllData)
                setMenuData(data)
                if (data.length === 0) closeModal(true, open)
            }
        })
        fetchApi.GET(wmService,
            {
                apiId: 'printer',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setPrintData(res.data)
                }
            });
    }
    const handleSelectAllChange = (e: any) => {
        // 全选/取消全选
        const checked = e.target.checked;
        setSelectAll(checked);
        if (checked) {
            setSelectedData(allData);
        } else {
            setSelectedData([]);
        }
    };
    const handleCheckboxChange = (checked: boolean, id: any) => {
        // 更新选中的数据
        if (checked) {
            let newSelectedData = [...selectedData, id];

            setSelectedData(newSelectedData)

            if (newSelectedData.length === menuData.length) setSelectAll(true)

        } else {
            let data = selectedData.filter((i) => i !== id)
            setSelectedData(data);
            // let data = selectedData.filter((dataItem) => dataItem !== id);
            if (data.length < menuData.length) setSelectAll(false)

            // setSelectedData(data);
        }

    };
    const isOrderTakingEx = (isOrderTaking: boolean) => {
        if (selectedData.length === 0) {
            antMessage("error", `请选中`, 'error');
            return false
        }

        fetchApi.PUT(wmService, {
            apiId: "cateringOrder",
            apiExtend: "isOrderTaking",
            apiData: {
                ...data,
                isOrderTaking,
                menuData: selectedData
            }
        }).then((res) => {
            if (res.success) {
                initData(2)
                antMessage('success', `${isOrderTaking ? "接单" : "拒接"}成功`, 'success');
            }
        }).catch((err) => {
            antMessage('error', '错误，执行出错', err.message + ' | status：' + err.errors.status);
            console.log("err", err);

        })
    }
    const changeMemo = (e: any, item: any) => {
        let value = e.target.value;
        fetchApi.POST(wmService, {
            apiId: "cateringOrder",
            apiExtend: "changeMemo",
            apiData: {
                id: item.id,
                memo: value,
            }
        }).then((res) => {
            if (res.success) {
                initData()
                antMessage('success', `修改备注成功`, 'success');
            }

        })
            .catch((err) => {
                antMessage("error", `修改备注失败`, '失败');
            })
    }

    const reprint = (printType: string) => {

        switch (printType) {
            case "cateringCheckoutDetailAccountsOrder":
            case "cateringCheckoutDeptAccountsOrder":
            case "cateringCheckoutClassifyAccountsOrder":
                if (data.caOrderStatus === 999 || data.caOrderStatus === 998) {

                } else {
                    antMessage('warning', `补打预结账需要处于预结账或结账状态`, 'warning');
                    return false
                }
                break;
            case "reCateringCheckoutDetailAccountsOrder":
            case "reKitchenDeptOrder":
                if (selectedData.length === 0) {
                    antMessage('warning', `选择补打的订单需要勾选菜品`, 'warning');
                    return false;
                }

                let exists = menuData.some((item: any) => selectedData.includes(item.id) && item.chargebackStatusId === 0);
                if (exists) {
                    antMessage('warning', `请勾选退款/退款中的菜品`, 'warning');
                    return false
                }

                if (data.caOrderStatus === 999 || data.caOrderStatus === 996) {

                } else {
                    antMessage('warning', `补打退款订单需要处于结账或交易关闭状态`, 'warning');
                    return false
                }
                break;
            case "consumptionListOrder":
                if (data.caOrderStatus !== 999) {
                    antMessage('warning', `消费清单需要处于结账状态下`, 'warning');
                    return false
                }
                break
            default:
                if (selectedData.length === 0) {
                    antMessage('warning', `选择补打的订单需要勾选菜品`, 'warning');
                    return false
                }
                break;
        }
        fetchApi.POST(wmService, {
            apiId: "cateringOrder",
            apiExtend: "reprint",
            apiData: {
                id: data.id,
                printType,
                selectedData,
                isIo: true,
                printerIds,
                dataStatusType: data.dataStatusType
            }
        }).then((res) => {
            initData()
            antMessage('success', `补打成功`, 'success');
        }).catch((err) => {
            antMessage("error", err, '失败');
        })
    }
    /** 管理菜单项 */
    const manageItems = (orderId: string) => {
        const items: MenuProps['items'] = [
            {
                label: "明细结账单",
                key: '{"orderId":"' + orderId + '","id":"cateringCheckoutDetailAccountsOrder"}',
            },
            {
                label: "部门结账单",
                key: '{"orderId":"' + orderId + '","id":"cateringCheckoutDeptAccountsOrder"}',
            },
            {
                label: '分类结账单',
                key: '{"orderId":"' + orderId + '","id":"cateringCheckoutClassifyAccountsOrder"}'
            },
            {
                label: '明细退款单',
                key: '{"orderId":"' + orderId + '","id":"reCateringCheckoutDetailAccountsOrder"}'
            },
            {
                label: '消费清单',
                key: '{"orderId":"' + orderId + '","id":"consumptionListOrder"}'
            },
            // {
            //     label: '支付查询',
            //     key: '{"orderId":"' + orderId + '","id":"orderQuery"}'
            // },



        ];
        return items;
    };
    /**
      * 点击触发
      * @param param
      */
    const onManageItemsClick = ({ key }: any) => {
        let keyJson = JSON.parse(key);
        const { id } = keyJson;
        reprint(id)
    };
    /**
     * 
     */
    const onChangePrinter = (e: any) => {
        setPrinterIds(e)

    }
    return (
        <Drawer closable={false} onClose={() => closeModal(true, 3)} width={"75%"} extra={
            <Space style={{ marginLeft: "30px" }}>
                {data.type === "pending" ? <>
                    <Button style={{ width: 100, height: 50 }} onClick={() => isOrderTakingEx(true)} size="large" type="primary">接受</Button>
                    <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要拒绝吗？</div>} onConfirm={() => isOrderTakingEx(false)} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button style={{ width: 100, height: 50 }} size="large" danger>拒绝</Button>
                    </Popconfirm>
                </> : <>
                    <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要补打吗？</div>} onConfirm={() => reprint("kitchenDeptOrder")} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button size="large" danger>堂口单补打</Button>
                    </Popconfirm>
                    <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要补打吗？</div>} onConfirm={() => reprint("reKitchenDeptOrder")} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button size="large" danger>退款堂口单补打</Button>
                    </Popconfirm>
                    <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要补打吗？</div>} onConfirm={() => reprint("customerViewingOrder")} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button size="large" danger>客看单补打</Button>
                    </Popconfirm>
                    <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要补打吗？</div>} onConfirm={() => reprint("kitchenTotalOrder")} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button size="large" danger>厨总单补打</Button>
                    </Popconfirm>
                    <Dropdown menu={{ items: manageItems(data.id), onClick: (key) => onManageItemsClick(key) }} trigger={["hover"]}>
                        <Space>
                            <Button size={"large"} type="dashed" >结账单</Button>
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                    <Tooltip placement="topLeft"  >
                        <Select
                            showSearch
                            onChange={onChangePrinter}
                            // popupMatchSelectWidth={100}
                            style={{ width: 200 }}
                            mode="multiple"
                            allowClear
                            placeholder="指定打印机"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(printData, 'printerName')}
                        </Select>
                    </Tooltip>
                    {/* <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要补打吗？</div>} onConfirm={() => reprint("cateringCheckoutDetailAccountsOrder")} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                        <Button size="large" danger>明细预结单补打</Button>
                    </Popconfirm> */}
                </>}
            </Space>
        } title={
            <>
                <div
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>                    <Checkbox style={{ transform: 'scale(2.5)', marginLeft: 30 }} checked={selectAll} onChange={handleSelectAllChange}>
                        全选
                    </Checkbox>
                    {/* {data.caOrderStatus === 1 ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                        <div style={{ fontSize: 35 }}>{Math.abs(dayjs(data.time).diff(dayjs(), "minute")) + "分前"}</div>

                    </div> : null} */}
                    {/* {data.type === 'noPending' ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                     


                    </div> : null} */}
                    <div>
                        <div style={{ fontSize: 35 }}>{data.roomNum}</div>

                    </div>
                </div>
            </>

        } placement="left" open={true}>
            <List
                dataSource={menuData}
                renderItem={(item: any, index: number) => {
                    return (
                        <List.Item

                            key={item.id}
                            actions={[
                                <Checkbox style={{ transform: 'scale(2.5)' }} checked={selectedData.includes(item.id)} onChange={(e) => handleCheckboxChange(e.target.checked, item.id)}>
                                </Checkbox>
                            ]}
                        >
                            <List.Item.Meta
                                title={<div style={{ fontSize: "30px", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "60%" }}>{`${index + 1}.${item.menuName}(${item.menuSpecName})`}</div>
                                    <div style={{ width: "15%" }}>{item.kitchenDeptPrintName}</div>
                                    <div style={{ width: "5%" }}>{`x${item.amount}`}</div>
                                    <div style={{ width: "20%" }}>{`￥${item.advancePrice}`}</div>
                                    {/* {data.caMenuStatus === 1 ? <Popconfirm placement={"bottom"} title="确定要拒绝吗？" okText="确定" cancelText="取消">
                                        <Button size="large" danger>拒绝</Button>
                                    </Popconfirm> : ""} */}
                                </div>}
                                description={<div style={{ fontSize: "25px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ width: "65%" }}>{item.nonDishName}</div>
                                    <div style={{ width: "10%" }}>{item.chargebackStatusName}</div>

                                    {data.type === "pending" ? <div style={{ width: "25%" }}><Input size="large" defaultValue={item.memo} onBlur={(e) => changeMemo(e, item)} /></div> : <div style={{ width: "25%" }}>{item.memo}</div>}
                                    {/* {data.caMenuStatus !== 1 ? <div style={{ width: "10%" }}>{Math.abs(dayjs(item.time).diff(dayjs(), "minute")) + "分前"}</div> : null} */}
                                    {/* <div style={{ width: "10%" }}>{Math.abs(dayjs(item.time).diff(dayjs(), "minute")) + "分前"}</div> */}
                                </div>}
                            />
                            <div>{item.price}</div>
                        </List.Item>
                    )
                }}
            />
        </Drawer>
    )
}
