import { Button, Input, Select, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import getList from '../universal/getList';
import dayjs from 'dayjs';
import WebUniversal, { WUExtendPropsType, initDataType } from './WebUniversalNewEx'
import antMessage from '../../utils/extend/AntdNotification';

//组件 WebGlobalData
const WebGlobalData = (props: any) => {
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'globalData';
    const [id, setId] = useState('');
    const [maxCountData, setMaxCountData] = useState('');
    const [globalDataRow, setGlobalDataRow] = useState();
    /** 后端数据wmService */
    let wmService: any = '';
    const [service, setService] = useState('');
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            setService(wmService);
        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'globalData',
                    apiExtend: 'list'
                }).then(res => {
                    if (res.success === true) {
                        setGlobalDataRow(res.data);
                    }
                })
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /** 查看不同id的全局变量 */
    const idChange = (e: any) => {
        const currentData: initDataType = {
            apiId,
            sendPageData: { id: e }
        }
        ref.current.initData(currentData);
        setId(e);
        getMaxcount(e);
    };

    //表头
    const columns = [{
        title: '序号',
        dataIndex: 'id',
        render: (text: any, record: any, index: any) => (
            index + 1
        )
    },
    {
        title: '缓存',
        dataIndex: 'id',
        render: (text: any, record: any) => {
            /** 缓存 */
            var cache: any;
            if (typeof record === 'object') {
                cache = JSON.stringify(record);
                cache = JSON.parse(cache);
                delete cache['_globalDataOptions'];
                cache = JSON.stringify(cache);
            }
            else {
                cache = record;
            }
            return <Tooltip placement="topLeft" title={cache}>{
                typeof cache !== "undefined" ?
                    (cache.length >= 70 ? `${cache.substring(0, 70)}......` : cache)
                    : ''
            }</Tooltip>
        }
    },
    {
        title: '更新',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            if (typeof text !== 'undefined') {
                const { createdAt, updatedAt } = text;
                if (typeof updatedAt !== 'undefined') {
                    let createdAtStr = '';
                    const updatedAtStr = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
                    if (typeof createdAt !== 'undefined') {
                        createdAtStr = `创建：${dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')} 更新：${updatedAtStr}`;
                    }
                    return <Tooltip placement="topLeft" title={createdAtStr}>
                        {updatedAtStr}
                    </Tooltip>
                }
            }
        }
    },
    {
        title: '查询',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            if (typeof text !== 'undefined') {
                const { queryedAt } = text;
                if (typeof queryedAt !== 'undefined') {
                    return dayjs(queryedAt).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        }
    },
    {
        title: '次数',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            if (typeof text !== 'undefined') {
                const { queryCount } = text;
                return queryCount;
            }
        }
    },
    ];

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '缓存',
        apiId,
        columns,
        showAdd: false,
        showOperation: true,
        showEdit: false,
        showQuery: true,
        pagination: true,
        sendPageData: { currentId: id }
    };

    /** 获取最大条数 */
    const getMaxcount = (id: string) => {
        fetchApi.GET(service,
            {
                apiId: 'globalData',
                apiVariable: id,
                apiExtend: 'getMaxcount'
            }).then(res => {
                if (res.success === true) {
                    setMaxCountData(res.data);
                }
            })
    };

    const setMaxCount = () => {
        fetchApi.POST(service,
            {
                apiId: 'globalData',
                apiExtend: 'setMaxcount',
                apiData: {
                    id,
                    maxCount: maxCountData
                }
            }).then(res => {
                if (res.success === true) {
                    antMessage('success', '设置成功');
                } else {
                    antMessage('error', '设置失败', '请检查!');
                }
            })
    };

    return (
        <>
            {/* <div style={{
                display: "flex",
                justifyContent: "flex-start"
            }}> */}
            <Select
                showSearch
                defaultValue=""
                onChange={idChange}
                placeholder="缓存id"
                optionFilterProp="children"
                style={{ width: '200px' }}
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(globalDataRow, undefined, undefined, true)}
            </Select>
            &nbsp;
            <Input
                style={{ width: '100px' }}
                value={maxCountData}
                placeholder='条数'
                onChange={(e) => {
                    setMaxCountData(e.target.value);
                }}
            ></Input>
            &nbsp;
            <Button onClick={setMaxCount}>设置</Button>
            {/* </div> */}
            <span>   </span>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGlobalData;