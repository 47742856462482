import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuSpecEdit from './MenuSpecEdit';
import { Button, Space, Switch, Tooltip } from 'antd';
import MenuSpecSpecial from './MenuSpecSpecial';
import MenuSpecUploadFile from './MenuSpecUploadFile';
import MenuPeriodDiscount from './MenuPeriodDiscount';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';

export default function MenuSpec(props: any) {

    const ref = useRef<any>();

    const { closeModal, data } = props;

    /** 更改一项数据 */
    // let handleChangeOne: any;

    //规格窗口
    const [menuSpecSpecialVisible, setMenuSpecSpecialVisible] = useState(false);

    //规格数据
    const [specSpecialData, setSpecSpecialData] = useState<any>();

    //时段打折窗口
    const [menuPeriodDiscountVisible, setMenuPeriodDiscountVisible] = useState(false);

    //时段打折数据
    const [menuPeriodDiscountData, setMenuPeriodDiscountData] = useState<any>();


    //常规规格库存设置窗口
    // const [inventoryVisible, setInventoryVisible] = useState(false);

    //常规规格库存数据
    // const [inventoryData, setInventoryData] = useState<any>();

    /** api服务id */
    const apiId = 'menuSpec';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    //打开特殊属性
    const handleMenuSpecSpecial = (record: any) => {
        //console.log(record)
        sendData.menuSpecId = record.id;
        sendData.menuSpecName = record.menuSpecName;
        sendData.foodType = data.foodType;
        setSpecSpecialData(sendData);
        setMenuSpecSpecialVisible(true);
    };

    //打开时段打折页面
    const handleMenuPeriodDiscount = (record: any) => {
        //console.log(record)
        sendData.menuId = record.menuId;
        sendData.menuSpecId = record.id;
        sendData.menuSpecName = record.menuSpecName;
        sendData.price = record.price;
        setMenuPeriodDiscountData(sendData);
        setMenuPeriodDiscountVisible(true);
    };

    //关闭时段打折页面
    const onCloseMenuPeriodDiscount = async (status: boolean) => {
        setMenuPeriodDiscountVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭特殊规格页面
    const onCloseMenuSpecSpecial = async (status: boolean) => {
        setMenuSpecSpecialVisible(false);
        if (status) {
            await initData();
        }
    }

    const initData = async () => {
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                menuId: data.menuId,
                branchStoreMenuWarehouseId: data.branchStoreMenuWarehouseId,
                foodType: data.foodType
            }
        }
        ref.current.initData(currentData);
    }

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menuSpec",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //关闭常规库存设置窗口
    // const onCloseInventoryVisible = (status: boolean) => {
    //     setInventoryVisible(false)
    //     if (status) {
    //         ref.current.initData()
    //     }
    // }

    //打开常规库存设置窗口
    // const openInventoryVisible = (item: any) => {
    //     let inventory = item.inventory
    //     let type = inventory ? "EDIT" : "NEW"
    //     setInventoryVisible(true)
    //     setInventoryData({
    //         id: item.id,
    //         menuWarehouseSpecId: item.menuWarehouseSpecId,
    //         inventoryId: inventory?.id,
    //         type
    //     })
    //     // console.log(item);
    // }

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) =>
                <div>{record.menu.foodName}</div>
        },
        {
            title: data.foodType === 'setMeal' ? '规格 / 套餐类别' : '规格',
            dataIndex: 'menuSpecName',
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '活动价',
            dataIndex: 'promotionPrice',
        },
        {
            title: '限购数量',
            dataIndex: 'limit',
        },

        data.key !== 'menuToPromo' ?
            {
                title: data.foodType === 'setMeal' ? '套餐菜式' : '属性',
                dataIndex: 'menuSpecSpecial',
                key: 'menuSpeSpecial',
                render: (text: any, record: any) => {
                    if (data.foodType === 'setMeal') {
                        let num: any = 0;
                        for (var i = 0, len = record.menuSpecSpecials.length; i < len; i++) {
                            if (record.menuSpecSpecials[i].setMealRelationId) {
                                num = Number(num) + 1;
                            }
                        }

                        if (record.isSpecial) {
                            if (record.menuSpecSpecials.length === num) {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleMenuSpecSpecial(record)}>{data.foodType === 'setMeal' ? '查看' : '添加'}</Button>
                                    </Space>
                                )
                            }
                            else {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleMenuSpecSpecial(record)} danger>{data.foodType === 'setMeal' ? '查看' : '添加'}</Button>
                                    </Space>
                                )
                            }
                        }
                    }
                    else {
                        if (record.isSpecial) {
                            return (
                                <Space size="small">
                                    <Button size={"small"} type="primary" onClick={() => handleMenuSpecSpecial(record)}>{data.foodType === 'setMeal' ? '查看' : '添加'}</Button>
                                </Space>
                            )
                        }
                    }
                }
            } : {},

        // {
        //     title: '几选几',
        //     dataIndex: 'chooseNum',
        //     render: (text: any, record: any) => {
        //         console.log(record);
        //         if (record.menuSpecSpecials && record.isSpecial) {
        //             return (
        //                 <div>{record.menuSpecSpecials.length + " 选 " + record.chooseNum}</div>
        //             )
        //         }
        //     }
        // },

        {
            title: data.foodType === 'setMeal' ? '套餐类别' : '特殊规格',
            dataIndex: 'isSpecial',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) =>
                //     typeof handleChangeOne !== "undefined" ?
                //         handleChangeOne(record.id, 'isSpecial', e, apiId) :
                //         undefined
                // }
                />
            )
        },

        data.foodType === 'setMeal' ?
            {
                title: '几选几',
                dataIndex: 'chooseNum',
                render: (text: any, record: any) => {
                    if (record.menuSpecSpecials && record.isSpecial) {
                        if (Number(record.menuSpecSpecials.length) < Number(record.chooseNum)) {
                            return (
                                <div style={{ color: "red" }}>{record.menuSpecSpecials.length + " 选 " + record.chooseNum}</div>
                            )
                        }
                        else {
                            return (
                                <div>{record.menuSpecSpecials.length + " 选 " + record.chooseNum}</div>
                            )
                        }
                    }
                }
            } : {},

        data.foodType !== 'setMeal' ?
            {
                title: '强制数量',
                dataIndex: 'isMandatory',
                key: 'isMandatory',
                render: (text: any, record: any) => {
                    if (!record.isSpecial) {
                        return (
                            <Switch checkedChildren="强制" unCheckedChildren="不强制"
                                checked={text} key={record.id}
                            />
                        )
                    }
                }
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '时段',
                dataIndex: 'menuPeriodDiscount',
                key: 'menuPeriodDiscount',
                render: (text: any, record: any) => {
                    if (record.isPeriodDiscount) {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" onClick={() => handleMenuPeriodDiscount(record)}>{'添加'}</Button>
                            </Space>
                        )
                    }
                }
            } : {},

        {
            title: '时段打折',
            dataIndex: 'isPeriodDiscount',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '必选',
            dataIndex: 'isRequired',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        // {
        //     title: '库存设置',
        //     dataIndex: 'price',
        //     render: (text: any, record: any) =>
        //         <Space size="small">
        //             <Button size={"small"} type="primary" onClick={() => openInventoryVisible(record)}>库存</Button>
        //         </Space>
        // },
        {
            title: '外部默认显示',
            dataIndex: 'isExternalDefault',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isExternalDefault', e)}
                    />
                )
            }
        },
        {
            title: '客服专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isStaff', e)}
                    />
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: data.foodType === 'setMeal' ? '套餐菜式' : '规格特殊属性',
            dataIndex: 'menuSpecSpecialName',
        },
        {
            title: data.foodType === 'setMeal' ? '价格' : '加价',
            dataIndex: 'price',
        },

        data.foodType === 'setMeal' ?
            {
                title: '数量',
                dataIndex: 'amount',
            } : {},

        data.foodType === 'setMeal' ?
            {
                title: '单位',
                dataIndex: 'productUnitId',
                render: (text: any, record: any) => {
                    if (record.productUnitId) {
                        return (
                            <div>{record.productUnit.productUnitName}</div>
                        )
                    }
                }
            } : {},

        {
            title: '排序',
            dataIndex: 'sortBy',
        },

        data.foodType === 'setMeal' ?
            {
                title: '关联Id',
                dataIndex: 'Id',
                key: 'Id',
                render: (text: any, record: any) => {
                    return (
                        <div>{record.setMealRelationId}</div>
                    )
                }
            } : {},
    ];

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuId: '',
        menuSpecId: '',
        menuSpecName: '',
        foodType: '',
        price: 0
    }

    // /**关闭窗口并刷新数据 */
    // const onClose = (status: Boolean) => {
    //     closeModal(false);
    //     if (status === true) {
    //         getData(true);
    //     }
    // }

    const handleOk = (e: any) => {
        closeModal(true);
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: data.foodType === 'setMeal' ? '规格 / 套餐类别' : '菜式规格',
        apiId,
        columns,
        showImage: true,
        showUploadFile: true,
        showDefault: data.key === 'menuToPromo' ? false : true,
        showStates: data.key === 'menuToPromo' ? false : true,
        showEdit: data.key === 'menuToPromo' ? false : true,
        showDelete: data.key === 'menuToPromo' ? false : true,
        showAdd: data.key === 'menuToPromo' ? false : true,
        showQuery: data.key === 'menuToPromo' ? false : true,
        pagination: true,
        // showDelete: false,
        extendIdName: "menuId",
        EditComponent: MenuSpecEdit,
        arrayFilesName: 'menuSpecFiles',
        UploadFileComponent: MenuSpecUploadFile,
        extendObj: {
            key: "isSpecial",
            values: false,
            isPromoCategory: data.key === 'menuToPromo' ? true : false,
        },
        sendPageData: {
            menuId: data.menuId,
            branchStoreMenuWarehouseId: data.branchStoreMenuWarehouseId,
            foodType: data.foodType
        },

        //子表格
        isExpandable: data.key === 'menuToPromo' ? false : true,
        expandableColumns,
        expandableDataName: 'menuSpecSpecials',
    };

    return (
        <>
            <AntDraggableModal
                title={data.foodType === 'setMeal' ? '规格 / 套餐类别' : '菜式规格'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1700}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {
                menuSpecSpecialVisible
                    ? (<MenuSpecSpecial
                        closeModal={onCloseMenuSpecSpecial} data={specSpecialData} />)
                    : null
            }

            {
                menuPeriodDiscountVisible
                    ? (<MenuPeriodDiscount
                        closeModal={onCloseMenuPeriodDiscount} data={menuPeriodDiscountData} />)
                    : null
            }
        </>
    )
}