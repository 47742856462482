import { Form, Space, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import WebEditUniversal from '../system/WebEditUniversalNewEx';
import antMessage from '../../utils/extend/AntdNotification'
import WebBehaviors, { behaviorsDataType } from '../system/behaviors/WebBehaviorsNew';
import systematic from '../../config/systematic';
import systemExpand from '../../config/systemExpand';
import PhotoGallery from '../filesSystem/PhotoGallery';
import FilesManage from '../filesSystem/FilesManage';

/** UploadFileListUniversal ExtendProps数据类型 */
export declare type UFLUExtendPropsType = {
    /** apiId */
    apiId: string,
    /** 标题名称 */
    titleName: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示下载按钮 */
    showDownload?: boolean,
    /**是否显示推广 */
    showPromo?: boolean,
    /** 是否显示默认选项 */
    showDefault?: boolean,
    /** 是否显示状态选项 */
    showStates?: boolean,
    /** 组件：文件编辑组件 */
    EditComponent?: any,
    /** 附加数据 */
    sendPageData?: any,
    /** 表头 */
    filesColumns?: Array<any>,
    /** 文件组id */
    filesGroupId: string,
    /** 子目录 */
    subdirectory?: string,
    /** 第二子目录 */
    secondSubdirectory?: string,
    /** 数据 */
    data?: any,
    /** 方法：关闭窗口 */
    onClose?: Function,
    extendIdName?: string,
    fileType?: "files" | "wiscaDisk",
    extendObj?: any,
    showFileUpload?: boolean,
    showFileSelection?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除按钮 */
    showDelete?: boolean,
    /** 是否显示编辑按钮 */
    showEdit?: boolean,
};

/**
 * 上传文件通用组件
 * @param props 
 * @returns 
 */
const UploadFileListUniversal = (props: any) => {

    /** 数据刷新中 */
    let loading = false;
    const [form] = Form.useForm(); //form
    let extendProps: UFLUExtendPropsType;
    extendProps = props.props;

    let {
        apiId,
        titleName,
        showImage,
        showDownload,
        showDefault,
        showStates,
        EditComponent,
        sendPageData,
        filesColumns,
        filesGroupId,
        subdirectory,
        secondSubdirectory,
        data,
        onClose,
        fileType,
        extendIdName,
        extendObj,
        showPromo,
        showFileUpload,
        showFileSelection,
        showOperation,
        showDelete,
        showEdit,
    } = extendProps;


    if (typeof showFileUpload === "undefined") { showFileUpload = true };
    if (typeof showFileSelection === "undefined") { showFileSelection = true };
    const { disabledUpload } = data;
    const ref = useRef<any>();
    const [modalEditData, setModalEditData] = useState({});
    //row数据
    const [rows, setRows] = useState<any>();
    //当前页
    const [currentData, setCurrentData] = useState(1);
    //每页行数
    const [pageSizeData, setPageSizeData] = useState(20);
    //总行数
    const [totalData, setTotalData] = useState(0);
    //查询条件
    const [queryData, setQueryData] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    //打开文件库
    const [photoGalleryVisible,
        setPhotoGalleryVisible] = useState(false);
    const [filesGroupData, setFilesGroupData] = useState<any>();
    const [filesData, setFilesData] = useState<any>();
    const [defaultTotal, setDefaultTotal] = useState<any>();
    /** 后端数据defaultService */
    const defaultService = systematic.wmService;
    //上传文件
    const [upload, setUpload] = useState<any>();
    //文件服务apiUrl
    const [filesService, setFilesService] = useState<any>();
    //文件服务id
    const [serviceId, setServiceId] = useState<any>();


    useEffect(() => {
        //初始化
        const fetchData = async () => {

            const filesServer = await systemExpand.filesServerEx(fileType!);
                
            const { apiServiceUrl, serviceId } = filesServer as any;
         console.log("filesGroupId",filesGroupId);
         
            setFilesService(apiServiceUrl);
            setServiceId(serviceId);
            setUpload(await FilesManage.upload(
                apiServiceUrl,
                serviceId,
                filesGroupId,
                {
                    subdirectory,
                    secondSubdirectory,
                    successCallback,
                })
            );
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //改变数据
    const handleChangeOne = (id: number, columnName: string, value: boolean, urlName: string) => {

        //同步数据 async - await
        const fetchData = async () => {
            const bodys = {
                apiId: apiId + 'File',
                apiVariable: id,
                apiExtend: 'updateOne',
                apiData: {
                    columnName,
                    value,
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            };
            if (await fetchApi.PUT(defaultService, bodys)) {
                antMessage('success', '编辑成功', 'updateOne ' + columnName + ' success');
                getFileList(currentData, pageSizeData, queryData);
            }
        }
        fetchData();
    };

    /** 上传图片参数 */
    const uploadData = {
        userId: 'system'
    };

    /** 关闭 */
    const handleClose = () => {
        if (typeof onClose !== "undefined") {
            onClose(true);
        }
    }

    /** 打开图库 */
    const OpenPhotoGallery = async () => {
        //由于没有选择默认图库的组，所以提前设定第一个组为默认
        fetchApi.GET(defaultService,
            {
                apiId: 'filesGroup',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    res.data.map(async (item: any, index: any) => {
                        if (index === 0) {
                            //读取数据库，首先将分组先set好
                            fetchApi.GET(defaultService,
                                {
                                    apiId: 'filesGroup',
                                    apiExtend: 'showList'
                                }).then(res => {
                                    if (res.success) {
                                        setFilesGroupData(res.data);
                                    }
                                })
                            //根据该分组读取数据库，将数据都set好
                            const res = await fetchApi.GET(defaultService, {
                                apiId: 'files',
                                apiExtend: 'groupShowPage',
                                current: 1,
                                pageSize: 32,
                                /** 文件组id */
                                filesGroupId
                            })
                            if (res.success) {
                                setFilesData(res.data.rows);
                                setDefaultTotal(res.data.total);
                            }
                            setPhotoGalleryVisible(true);
                        }
                    })
                }
            })
    };

    /**
     * 关闭图库
     */
    const closePhotoGallery = (state: boolean) => {
        setPhotoGalleryVisible(false);
    };

    /**
     * 获取选择好的图片，并添加到数据库
     */
    const getCheckPhoto = async (filesData: any, editor: any) => {

        fetchApi.POST(defaultService,
            {
                apiId: apiId + 'File',
                apiExtend: 'insertFiles',
                apiData: {
                    filesData,
                    dataId: data.id,
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            }).then(res => {
                if (res.success === true) {
                    //刷新表格内容
                    getFileList(currentData, pageSizeData, queryData);
                }
            })
    };

    /**
     * 删除图片
     * @param record 内容
     * @param isChecked 是否物理删除图片
     * @returns 
     */
    const handleDelete = async (record: any, isChecked: boolean) => {
        //删除文件数据
        const res = await fetchApi.DELETE(defaultService,
            {
                apiId: apiId + 'File',
                apiVariable: record.id,
                apiData: {
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            })
        if (res.success) {
            try {
                const filesIds = [{ id: record.fileId }];
                let delMessage: any;
                //删除文件管理的数据(物理删除)
                if (isChecked === true) {
                    delMessage = await fetchApi.DELETE(filesService,
                        {
                            apiId: 'files',
                            apiExtend: 'deleteFiles',
                            apiData: {
                                filesIds
                            }
                        }, undefined, {
                        'serviceId': serviceId
                    })
                }
                antMessage('success', '文件删除成功', delMessage ? delMessage.data : 'success');
            }
            catch (err: any) {
                antMessage('error', '删除失败', err.message);
            }
        }
        else {
            antMessage('error', '图片删除失败', 'error');
        }
        //刷新
        getFileList(currentData, pageSizeData, queryData);
    };

    //更改文件数据
    let sendData = {
        type: '',
        id: '',
    }
    //打开[文件编辑]框
    const handleEdit = (e: any) => {
        sendData.type = 'EDIT';
        sendData.id = e.id;
        setModalEditData(sendData);
        setModalEditVisible(true);
    }

    //子组件回调函数，关闭modalEdit
    const onCloseModalEdit = (state: boolean) => {
        setModalEditVisible(false)
        if (state === true) {
            //刷新表格内容
            getFileList(currentData, pageSizeData, queryData);
        }
    };

    /**
     * 图片上传后回调的方法 
     * @param info 文件数据
     */
    const successCallback = async (info: any) => {

        console.log("info", info);
        //保存数据
        let insertId = apiId + "Id";

        await fetchApi.POST(defaultService,
            {
                apiId: apiId + 'File',
                apiData: {
                    [insertId]: data.id,
                    fileId: info.data.id,
                    fileDestination: info.data.destination + '/' + info.data.fileName,
                    fileType: info.data.fileType,
                    fileSize: info.data.fileSize,
                    imageWidth: info.data.imageWidth,
                    imageHeight: info.data.imageHeight,
                    isDefault: false,
                    isUse: true,
                    smallFileDestination: info.data.smallImage ? info.data.destination + '/' + info.data.smallImage : null,
                }
            })
        //刷新
        getFileList(currentData, pageSizeData, queryData);
    };

    /**
     * 获取文件列表数据（刷新）
     */
    const getFileList = async (
        current: number,
        pageSize: number,
        queryString: string) => {
        if (loading === true) { return }
        loading = true;
        try {
            const res = await fetchApi.GET(
                defaultService, {
                apiId: apiId + 'File',
                apiVariable: data.id,
                apiExtend: 'showPage',
                apiData: {
                    sendPageData,
                    current,
                    pageSize,
                    queryString
                }
            }); //req.query 
            if (res.success) {

                setRows(res.data.rows);
                setCurrentData(res.data.current);
                setPageSizeData(res.data.pageSize);
                setTotalData(res.data.total);
                setQueryData(res.data.queryString)
            }
            loading = false;
        }
        catch { }
    };

    const handleChangeDefault = async (record: any, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiExtend: 'changeDefault',
            apiData: {
                id: record.id,
                [extendIdName!]: record[extendIdName!],
                extendIdName,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        };

        fetchApi.PUT(defaultService, bodys).then(async (res) => {
            if (res.success === true) {
                antMessage('success', 'changeDefault编辑成功',
                    'apiId：' + bodys.apiId + '，columnName：' +
                    bodys.apiData.columnName + '，value：' + bodys.apiData.value);

                getFileList(currentData, pageSizeData, queryData);
                return true;
            }
        }).catch((err) => {
            antMessage('error', '编辑失败', err.message);
            return false;
        })


    };

    //初始化
    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            await getFileList(currentData, pageSizeData, queryData);
            setModalVisible(true);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let uploadExtendProps: any = {
        ...props.props,
        form,
        apiId,
    };
    let behaviorsColumns = filesColumns!
        .concat([
        ]);

    let behaviorsData: behaviorsDataType = {
        apiId: apiId + 'File',
        showImage,
        showDownload,
        showDefault,
        showStates,
        showPromo,
        handleDelete,
        handleEdit,
        handleChangeOne,
        handleChangeDefault,
        showOperation,
        showDelete,
        showEdit,
        extendObj,
        behaviorsColumns,
        deleteButtonConfig: {
            useCheckBox: true,
            checkBoxContent: '同时物理删除图片',
            isChecked: false,
        }
    };

    let columns = WebBehaviors(behaviorsData);

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) =>
            getFileList(
                current,
                pageSize,
                queryData),
        onChange: (current: number) =>
            getFileList(
                current,
                pageSizeData,
                queryData),
    };

    return (
        <><WebEditUniversal ref={ref} props={uploadExtendProps} />
            <AntDraggableModal
                title={titleName}
                // visible={modalVisible}
                open={modalVisible}
                okText='退出'
                onOk={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={false}
                width={1080}
            >
                <Space>
                    {showFileUpload ? <Upload
                        {...upload
                        }
                        data={() => uploadData}
                        showUploadList={false}>
                        <Button disabled={disabledUpload} icon={<UploadOutlined />}>文件上传</Button>
                    </Upload> : null}

                    {showFileSelection ? <Button type="primary" onClick={OpenPhotoGallery}>文件库选取</Button> : null}


                </Space>
                <p />
                <Table
                    rowKey={row => row.id}
                    columns={columns}
                    dataSource={rows}
                    pagination={paginationProps}
                    size="small"
                />
            </AntDraggableModal>

            {modalEditVisible ? (<EditComponent closeModal={onCloseModalEdit} data={modalEditData} />) : null}
            {photoGalleryVisible
                ? (<PhotoGallery
                    closeModal={closePhotoGallery}
                    filesGroupData={filesGroupData}
                    defaultfilesData={filesData}
                    defaultMenu={filesGroupId}
                    defaultTotal={defaultTotal}
                    getCheckPhoto={getCheckPhoto}
                />)
                : null}
        </>
    )
}

export default UploadFileListUniversal;