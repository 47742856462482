import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import RoomTypeUploadFileEdit from "./RoomTypeUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";


//组件 WebProductUploadFile
const WebProductUploadFile = (props: any) => {
    
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    //表头
    const columnsHead = [
        {
            title: '房间类型',
            dataIndex: 'roomType',
            render: (text: any) => (text.roomType)
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '视频',
            dataIndex: 'movieId',
        },
       

    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);
        useEffect(() => {
            systemExpand.getConfig({
                id: 'config',
                nodeId: 'files'
            }).then(res => {
                const { files } = res as any;
                const {roomTypeFilesGroupId}=files;
                setExtendProps({
                    ...props,
                    titleName: '房间类型图片',
                    apiId: 'roomType',
                    filesGroupId: roomTypeFilesGroupId,
                    //+ File
                    //上传 + UploadFile
                    //删除 + DeleteFile
                    filesColumns,
                    showImage: true,
                    showDownload: true,
                    showStates: true,
                    showDefault: true,
                    showPromo:true,
                    fileType:"files",
                    extendIdName:"roomTypeId",
                    EditComponent: RoomTypeUploadFileEdit,
                })
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    // let extendProps: UFLUExtendPropsType = {
    //     ...props,
    //     titleName: '房间类型图片',
    //     apiId: 'roomType',
    //     //+ File
    //     //上传 + UploadFile
    //     //删除 + DeleteFile
    //     filesColumns,
    //     showImage: true,
    //     showDownload: true,
    //     showStates: true,
    //     showDefault: true,
    //     EditComponent:RoomTypeUploadFileEdit
    // };

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default WebProductUploadFile;