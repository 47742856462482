import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import MenuWarehouseArticleUploadFileEdit from "./MenuWarehouseArticleUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";

//组件 MenuWarehouseArticleUploadFile
export default function MenuWarehouseArticleUploadFile(props: any) {

    //extendProps 
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();

    //表头
    const columnsHead = [
        {
            title: '文章标题',
            dataIndex: 'articleTitle',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menuWarehouseArticle.articleTitle}</div>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { files } = res as any;
            const {articleFilesGroupId}=files;
            setExtendProps({
                ...props,
                titleName: '文章封面图',
                apiId: 'menuWarehouseArticle',
                filesGroupId: articleFilesGroupId,
                fileType: "files",
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                showFileUpload: true,
                showFileSelection: true,
                showOperation: true,
                extendIdName: "menuWarehouseArticleId",
                EditComponent: MenuWarehouseArticleUploadFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}