import React, { useEffect, useRef, useState } from 'react';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import antMessage from '../../../utils/extend/AntdNotification';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { Button, Popconfirm, Select, Space, Switch, Tooltip } from 'antd';
import MenuFoodEdit from '../menu/MenuFoodEdit';
import MenuOPLog from '../menu/MenuOPLog';
import MenuCondiment from '../menu/MenuCondiment';
import MenuSpec from './MenuSpec';
import MenuShopType from './MenuShopType';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import objectHandle from '../../../utils/baseLib/objectHandle';
import WarehouseToMenu from '../menu/WarehouseToMenu';
import MenuSalePeriod from '../menu/MenuSalePeriod';
import MenuSalesman from '../menu/MenuSalesman';
import MenuSpecSpecial from './MenuSpecSpecial';

//组件 ShowMenu
export default function ShowMenu(props: any) {

    const ref = useRef<any>();
    const { closeModal, data } = props;

    //规格窗口
    const [menuSpecVisible, setMenuSpecVisible] = useState(false);

    //调整小分类窗口
    const [shopTypeVisible, setShopTypeVisible] = useState(false);

    //添加推广菜式窗口
    const [menuToPromoVisible, setMenuToPromoVisible] = useState(false);

    //规格数据
    const [specData, setSpecData] = useState<any>();

    //设置加料窗口
    const [menuCondimentVisible, setMenuCondimentVisible] = useState(false);

    //设置加料数据
    const [menuCondimentData, setMenuCondimentData] = useState<any>();

    //设置操作日志窗口
    const [menuOPLogVisible, setMenuOPLogVisible] = useState(false);

    //设置操作日志数据
    const [menuOPLogData, setMenuOPLogData] = useState<any>();

    //小分类数据
    const [menuShopTypeData, setMenuShopTypeData] = useState<any>();

    //推广数据
    const [menuToPromoData, setMenuToPromoData] = useState<any>();

    //销售周期窗口
    const [menuSalePeriodVisible, setMenuSalePeriodVisible] = useState(false);

    //销售周期数据
    const [menuSalePeriodData, setMenuSalePeriodData] = useState<any>();

    //销售人员销售菜式窗口
    const [menuSalesmanVisible, setMenuSalesmanVisible] = useState(false);

    //销售人员销售菜式数据
    const [menuSalesmanData, setMenuSalesmanData] = useState<any>();

    const [shopTypeData, setShopTypeData] = useState<any>();
    const [shopTypeIdData, setShopTypeIdData] = useState<any>();

    /** api服务id */
    const apiId = 'menu';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                // apiId: "shopType",
                // apiExtend: 'showList'
                apiId: "menuShopType",
                apiExtend: 'showShopTypeList',
                menuHeadId: data.menuHeadId,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", shopTypeName: "所有分类" }, ...res.data];
                    setShopTypeData(newData)
                }
            })
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //打开规格
    const handleMenuSpec = (record: any) => {
        // console.log(record)
        sendData.key = data.key;
        sendData.menuId = record.id;
        sendData.foodName = record.foodName;
        // sendData.menuWarehouseId = record.menuWarehouseId;
        sendData.branchStoreMenuWarehouseId = record.branchStoreMenuWarehouseId;
        sendData.foodType = record.branchStoreMenuWarehouse.foodType;
        setSpecData(sendData);
        setMenuSpecVisible(true);
    };

    //打开设置加料
    const handleMenuCondiment = (record: any) => {
        //console.log(record)
        sendData.menuId = record.id;
        sendData.foodName = record.foodName;
        sendData.branchStoreMenuWarehouseId = record.branchStoreMenuWarehouseId;
        setMenuCondimentData(sendData);
        setMenuCondimentVisible(true);
    };

    //打开操作日志
    const handleMenuOPLog = (record: any) => {
        // console.log(record);
        sendData.menuId = record.id;
        sendData.foodName = record.foodName;
        sendData.isModal = true;
        setMenuOPLogData(sendData);
        setMenuOPLogVisible(true);
    };

    //查看不同分类的菜式
    const shopTypeChange = (e: any) => {

        setShopTypeIdData(e);
        const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            sendPageData: { shopTypeId: e, menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }


    //检查单位
    // const checkProductUnit = async (rows: any) => {

    //     let menuSpecData = [];
    //     for (var i = 0, len = rows.length; i < len; i++) {
    //         menuSpecData.push(rows[i].menuSpecName);
    //     }

    //     let returnData = await fetchApi.GET(wmService, {
    //         apiId: "menu",
    //         apiExtend: 'checkProductUnit',
    //         apiData: {
    //             menuSpecData
    //         }
    //     })
    //     // if (aa.data) {
    //     //     num = num + 1
    //     // }
    //     return returnData.data;
    // };

    //打开销售时段页面
    const handleMenuSalePeriod = (record: any) => {
        //console.log(record)
        sendData.menuId = record.id;
        sendData.foodName = record.foodName;
        setMenuSalePeriodData(sendData);
        setMenuSalePeriodVisible(true);
    };

    //关闭销售时段页面
    const onCloseMenuSalePeriod = async (status: boolean) => {
        setMenuSalePeriodVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    //打开销售时段页面
    const handleMenuSalesman = () => {
        sendData.menuHeadId = data.menuHeadId;
        setMenuSalesmanData(sendData);
        setMenuSalesmanVisible(true);
    };

    //关闭销售时段页面
    const onCloseMenuSalesman = async (status: boolean) => {
        setMenuSalesmanVisible(false);
        // if (status) {
        //     await initData();
        // }
    }


    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menu",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };


    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <div>
                        <Space size="small">
                            {data.key === 'menuToPromo' ? "" : record.isCopy ? "" :
                                <Popconfirm title="复制一个相同的菜式,是否执行?" onConfirm={() => handleCopy(record)}>
                                    <Button type='primary' size={"small"}>复制</Button>
                                </Popconfirm>
                            }
                            <Tooltip placement="topLeft" title={'对应的门店菜式为【 ' + record.branchStoreMenuWarehouse.foodName + ' 】'}>
                                <div>{record.foodName}</div>
                            </Tooltip>
                        </Space>
                    </div>
                )
            }
        },

        data.key === 'menuToPromo' ?
            {
                title: '菜谱类型',
                dataIndex: 'menuHeadName',
                render: (text: any, record: any) => (
                    <div>{record.menuHead.menuHeadName}</div>
                )
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '类别',
                dataIndex: 'shopTypeName',
                render: (text: any, record: any) => {
                    if (record.shopType) {
                        return (
                            <div>{record.shopType.shopTypeName}</div>
                        )
                    }
                }
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '单位',
                dataIndex: 'productUnitName',
                width: 150,
                render: (text: any, record: any) => {
                    // console.log(record);
                    if (record.checkProductUnit === record.menuSpecs.length && record.menuSpecs.length === 1) {
                        // 只有一个规格，而且规格和检查单位数量相同

                        if (objectHandle.isEmpty(record.branchStoreMenuWarehouse.productUnit) === false) {
                            // 单位不为空

                            if (record.menuSpecs[0].menuSpecName !== record.branchStoreMenuWarehouse.productUnit.productUnitName) {
                                // 单位和规格不同 (单位应该和规格相同)

                                return (
                                    <p>
                                        {record.branchStoreMenuWarehouse.productUnit.productUnitName}
                                        <div style={{ color: 'red' }}>单位和规格不同，请检查</div>
                                    </p>
                                )
                            }
                            else {
                                // 单位和规格相同 (正确操作)

                                if (record.branchStoreMenuWarehouse.productUnit) {
                                    return (
                                        <div>{record.branchStoreMenuWarehouse.productUnit.productUnitName}</div>
                                    )
                                }
                            }
                        }
                    }

                    if (record.checkProductUnit !== record.menuSpecs.length && record.menuSpecs.length === 1) {
                        // 只有一个规格，但规格和检查单位数量不相同 (单位和规格正常是要相同的)

                        if (record.checkProductUnit === 0) {
                            //唯一的规格都不是单位

                            if (!record.isPrintSpec) {
                                //没有选上打印规格
                                return (
                                    <p>
                                        <div style={{ color: 'red' }}>唯一的规格并不是单位，请检查打印规格是否选上</div>
                                    </p>
                                )
                            }
                            else {
                                //选了打印规格
                                if (record.branchStoreMenuWarehouse.productUnit) {
                                    return (
                                        <div>{record.branchStoreMenuWarehouse.productUnit.productUnitName}</div>
                                    )
                                }
                                else {
                                    return (
                                        <p>
                                            <div style={{ color: 'red' }}>唯一的规格不是单位，请检查单位是否有写上</div>
                                        </p>
                                    )
                                }
                            }
                        }
                    }

                    if (record.checkProductUnit === record.menuSpecs.length && record.menuSpecs.length > 1) {
                        // 有多个规格，而且规格全是单位

                        if (objectHandle.isEmpty(record.branchStoreMenuWarehouse.productUnit) === false) {
                            // 有多个规格，单位不为空 (单位应该为空)

                            return (
                                <p>
                                    {record.branchStoreMenuWarehouse.productUnit.productUnitName}
                                    <div style={{ color: 'red' }}>有多个规格，请检查单位</div>
                                </p>
                            )
                        }
                        else {
                            // 有多个规格，单位为空 (正确操作)
                            if (record.branchStoreMenuWarehouse.productUnit) {
                                return (
                                    <div>{record.branchStoreMenuWarehouse.productUnit.productUnitName}</div>
                                )
                            }
                        }
                    }

                    if (record.checkProductUnit !== record.menuSpecs.length && record.menuSpecs.length > 1) {
                        // 有多个规格，规格不全是单位 (单位应该要写的)

                        if (record.checkProductUnit === 0) {
                            //全部规格都不是单位

                            if (!record.isPrintSpec) {
                                //没有选上打印规格
                                return (
                                    <p>
                                        <div style={{ color: 'red' }}>有多个规格但不是单位，请检查打印规格是否选上</div>
                                    </p>
                                )
                            }
                            else {
                                //选了打印规格
                                if (record.branchStoreMenuWarehouse.productUnit) {
                                    return (
                                        <div>{record.branchStoreMenuWarehouse.productUnit.productUnitName}</div>
                                    )
                                }
                                else {
                                    return (
                                        <p>
                                            <div style={{ color: 'red' }}>有多个规格但不是单位，请检查单位是否有写上</div>
                                        </p>
                                    )
                                }
                            }
                        }
                        else {
                            //部分规格都不是单位
                            return (
                                <p>
                                    <div style={{ color: 'red' }}>有些规格是单位有些不是单位，请检查</div>
                                </p>
                            )
                        }
                    }
                }
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '销售周期',
                dataIndex: 'menuSalePeriod',
                key: 'menuSalePeriod',
                render: (text: any, record: any) => {
                    if (record.branchStoreMenuWarehouse.foodType !== 'setMeal') {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" onClick={() => handleMenuSalePeriod(record)}>销售周期</Button>
                            </Space>
                        )
                    }
                }
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '加料',
                dataIndex: 'menuComdiment',
                key: 'menuComdiment',
                render: (text: any, record: any) => {
                    if (record.branchStoreMenuWarehouse.foodType !== 'setMeal') {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" onClick={() => handleMenuCondiment(record)}>加料</Button>
                            </Space>
                        )
                    }
                }
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '价钱',
                dataIndex: 'price',
                render: (text: any, record: any) => {
                    if (record.menuSpecs.length > 0) {
                        return (
                            <div style={{ fontSize: 20, color: 'yellow' }}>
                                {record.menuSpecs.length === 1 ? record.menuSpecs[0].price : record.menuSpecs[0].price + " - " + record.menuSpecs[record.menuSpecs.length - 1].price}
                            </div>
                        )
                    }
                }
            } : {},

        {
            title: '规格',
            dataIndex: 'menuSpec',
            key: 'menuSpec',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleMenuSpec(record)}>规格</Button>
                    </Space>
                )
            }
        },

        data.key !== 'menuToPromo' ?
            {
                title: '排序',
                dataIndex: 'sortBy',
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '日志',
                dataIndex: 'menuOPLog',
                render: (text: any, record: any) => {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleMenuOPLog(record)}>日志</Button>
                        </Space>
                    )
                }
            } : {},

        data.key !== 'menuToPromo' ?
            sessionStorage.getItem('isAdmin') === 'true' ?
                {
                    title: '特殊显示',
                    dataIndex: 'isSpecialShow',
                    render: (text: any, record: any) => (
                        <Switch checkedChildren="显示" unCheckedChildren="不显示"
                            checked={text} key={record.id}
                        // onChange={(e) => handleChange(record.id, 'isSpecialShow', e)}
                        />
                    )
                } : {} : {},

        data.key !== 'menuToPromo' ?

            {
                title: '打印规格',
                dataIndex: 'isPrintSpec',
                render: (text: any, record: any) => (
                    <Switch checkedChildren="打印" unCheckedChildren="不打印"
                        checked={text} key={record.id}
                    // onChange={(e) => handleChange(record.id, 'isPrintSpec', e)}
                    />
                )
            } : {},

        // {
        //     title: '小程序显示',
        //     dataIndex: 'isWx',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="显示" unCheckedChildren="不显示"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isWx', e)}
        //         />
        //     )
        // },
        // {
        //     title: '客服专用',
        //     dataIndex: 'isStaff',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="是" unCheckedChildren="否"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isStaff', e)}
        //         />
        //     )
        // },

        data.key !== 'menuToPromo' ?
            {   //该项原来是客服专用，后来发现和小程序显示有点冲突，故目前两个合二为一，先暂停小程序显示，以客服专用暂时叫小程序显示
                title: '客人查看',
                dataIndex: 'isStaff',
                render: (text: any, record: any) => (
                    <Switch checkedChildren="不显示" unCheckedChildren="显示"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isStaff', e)}
                    />
                )
            } : {},

        data.key !== 'menuToPromo' ?
            {
                title: '打折',
                dataIndex: 'isDiscount',
                render: (text: any, record: any) => {
                    if (record.isDiscount) {
                        return (
                            <Tooltip placement="topLeft" title={'当前折扣为 ' + record.maxDiscount} color='red'>
                                <Switch checkedChildren="是" unCheckedChildren="否"
                                    checked={text} key={record.id}
                                />
                            </Tooltip>
                        )
                    }
                    else {
                        return (
                            <Switch checkedChildren="是" unCheckedChildren="否"
                                checked={text} key={record.id}
                            />
                        )
                    }
                }
            } : {},

        data.key === 'menuToPromo' ?
            {
                title: '推广',
                dataIndex: 'isPromo',
                render: (text: any, record: any) => (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isPromo', e)}
                    />
                )
            } : {},

        // {
        //     title: '外卖',
        //     dataIndex: 'isWm',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="是" unCheckedChildren="否"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isWm', e)}
        //         />
        //     )
        // },: {},

        data.key !== 'menuToPromo' ?
            sessionStorage.getItem('isAdmin') === 'true' ?
                {
                    title: '状态',
                    dataIndex: 'isUse',
                    render: (text: any, record: any) => (
                        <Switch checkedChildren="启用" unCheckedChildren="不启用"
                            checked={text} key={record.id}
                            onChange={(e) => handleChange(record.id, 'isUse', e)}
                        />
                    )
                } : {} : {},
    ];

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        // {
        //     title: "id",
        //     dataIndex: "branchStoreMenuWarehouseSpecName",
        //     render: (text: any, record: any) => {
        //         console.log("record", record);
        //         return <>
        //             {record.id}
        //         </>
        //     }
        // },
        {
            title: "规格",
            dataIndex: "menuSpecName",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {objectHandle.isEmpty(record.otherUnit) === false ? record.menuSpecName + "(" + record.otherUnit + ")" : record.menuSpecName}
                </>
            }
        },
        {
            title: "价格",
            dataIndex: "price",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.price}
                </>
            }
        },
        {
            title: "活动价",
            dataIndex: "promotionPrice",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.promotionPrice}
                </>
            }
        },
        {
            title: "限购数量",
            dataIndex: "limit",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.limit}
                </>
            }
        },
        // {
        //     title: "排序",
        //     dataIndex: "sortBy",
        //     render: (text: any, record: any) => {
        //         // console.log("record", record);
        //         return <>
        //             {record.sortBy}
        //         </>
        //     }
        // },
    ]

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuId: '',
        foodName: '',
        // menuWarehouseId: '',
        menuHeadId: '',
        menuHeadName: '',
        branchStoreMenuWarehouseId: '',
        foodType: '',
        isModal: false,

        //从推广分类传过来用的
        key: '',
        promoCategoryId: '',
        promoCategoryName: '',
        banquetId: '',
        brandId: '',
        brandName: '',
        branchStoreId: '',
        branchStoreName: ''
    }

    const handleOk = (e: any) => {
        closeModal(false);
    };

    const initData = async () => {
        const menuHeadId = data.menuHeadId;
        const promoCategoryId = data.promoCategoryId;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                shopTypeId: shopTypeIdData,
                menuHeadId: menuHeadId,
                promoCategoryId: promoCategoryId
            }
        }
        ref.current.initData(currentData);
    }

    //关闭调整类别顺序窗口
    const onCloseMenuShopType = async (status: boolean) => {
        setShopTypeVisible(false)
        if (status) {
            await initData();
        }
    }

    //关闭菜式规格窗口
    const onCloseMenuSpec = async (status: boolean) => {
        setMenuSpecVisible(false)
        if (status) {
            await initData();
        }
    }

    //调整类别顺序
    const handleShopTypeSortBy = () => {
        sendData.menuHeadId = data.menuHeadId;
        sendData.menuHeadName = data.menuHeadName;
        setMenuShopTypeData(sendData);
        setShopTypeVisible(true);
    };

    //添加推广菜式
    const handlePromoFood = () => {
        sendData.key = data.key;
        sendData.promoCategoryId = data.promoCategoryId;
        sendData.promoCategoryName = data.promoCategoryName;
        sendData.banquetId = data.banquetId;
        sendData.brandId = data.brandId;
        sendData.brandName = data.brandName;
        sendData.branchStoreId = data.branchStoreId;
        sendData.branchStoreName = data.branchStoreName;

        setMenuToPromoData(sendData);
        setMenuToPromoVisible(true);
    };

    //关闭穿梭框页面
    const onCloseMenuToPromo = async (status: boolean) => {
        setMenuToPromoVisible(false);
        if (status) {
            await initData();
        }
    }

    //自动更新菜式排序
    const handleMenuSortBy = () => {
        fetchApi.PUT(wmService, {
            apiId: "menu",
            apiExtend: 'changeSortBy',
            menuHeadId: data.menuHeadId,
        }).then(res => {
            if (res.success === true) {
                const menuHeadId = data.menuHeadId;
                const currentData: initDataType = {
                    apiId,
                    sendPageData: { menuHeadId: menuHeadId }
                }
                ref.current.initData(currentData);
            }
        })
    };

    //复制菜式
    const handleCopy = (record: any) => {
        fetchApi.PUT(wmService, {
            apiId: "menu",
            apiExtend: 'copy',
            apiData: {
                menu: record
            }
        }).then(res => {
            if (res.success === true) {
                const menuHeadId = data.menuHeadId;
                const currentData: initDataType = {
                    apiId,
                    sendPageData: { menuHeadId: menuHeadId }
                }
                ref.current.initData(currentData);
            }
        })
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: false,
        showOperation: data.key === 'menuToPromo' ? false : true,
        pagination: true,
        isAutomaticRefresh: true,
        showDelete: sessionStorage.getItem('isAdmin') === 'true' ? true : false,
        showAdd: false,
        EditComponent: MenuFoodEdit,
        // arrayFilesName: 'menuFiles',
        // UploadFileComponent: MenuUploadFile,
        sendPageData: { shopTypeId: shopTypeIdData, menuHeadId: data.menuHeadId, promoCategoryId: data.promoCategoryId },

        //子表格
        isExpandable: data.key === 'menuToPromo' ? false : true,
        expandableColumns,
        expandableDataName: 'menuSpecs',
    };

    return (
        <>
            <AntDraggableModal
                title={data.brandName + " " + data.branchStoreName + (data.key === 'menuToPromo' ? " 【" + data.promoCategoryName + "】 推广菜式" : " 【" + data.menuHeadName + "】 菜式")}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleOk}
                closable={false}
                // maskClosable={false}
                width={1650}>

                {
                    data.key !== 'menuToPromo' ?
                        <Select
                            style={{ width: 150 }}
                            // disabled={data.key !== 'menuToPromo' ? false : true}
                            showSearch
                            defaultValue="所有类别"
                            onChange={shopTypeChange}
                            placeholder="菜式类别"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.generalEx(shopTypeData, {
                                itemName: "shopTypeName",
                                includeNull: false
                            })}
                        </Select>
                        : ""
                }

                <span>&nbsp;&nbsp;</span>
                {
                    data.key !== 'menuToPromo' ?
                        <Button type="primary" onClick={handleShopTypeSortBy}>调整类别顺序</Button>
                        : ""
                }
                <span>&nbsp;&nbsp;</span>
                {
                    data.key !== 'menuToPromo' ?
                        <Button type="primary" onClick={handleMenuSalesman}>设置销售人员销售菜式</Button>
                        : ""
                }

                {
                    data.key === 'menuToPromo' ?
                        <Button type="primary" onClick={handlePromoFood}>添加推广菜式</Button>
                        : ""
                }

                <span>&nbsp;&nbsp;</span>
                {
                    data.key !== 'menuToPromo' ?
                        sessionStorage.getItem('isAdmin') === 'true' ?
                            <Popconfirm title="将所有菜式重新整理顺序,是否执行?"
                                onConfirm={() => handleMenuSortBy()}>
                                <Button type='primary'>自动更新菜式排序</Button>
                            </Popconfirm>
                            : ""
                        : ""
                }
                <span>&nbsp;&nbsp;</span>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {menuSpecVisible
                ? (<MenuSpec
                    closeModal={onCloseMenuSpec} data={specData} />)
                : null}

            {menuCondimentVisible
                ? (<MenuCondiment
                    closeModal={setMenuCondimentVisible} data={menuCondimentData} />)
                : null}

            {menuOPLogVisible
                ? (<MenuOPLog
                    closeModal={setMenuOPLogVisible} data={menuOPLogData} />)
                : null}

            {shopTypeVisible
                ? (<MenuShopType
                    closeModal={onCloseMenuShopType} data={menuShopTypeData} />)
                : null}

            {menuToPromoVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseMenuToPromo} data={menuToPromoData} />)
                : null}

            {
                menuSalePeriodVisible
                    ? (<MenuSalePeriod
                        closeModal={onCloseMenuSalePeriod} data={menuSalePeriodData} />)
                    : null
            }

            {
                menuSalesmanVisible
                    ? (<MenuSalesman
                        closeModal={onCloseMenuSalesman} data={menuSalesmanData} />)
                    : null
            }
        </>
    )
}